import React, { useRef, useState } from 'react';

import Input from '@/components/inputs/input';
import LoadingOverlay from '@/components/loading';
import { isEmail, isValidPhoneNumber, toggleLanguage } from '@/helper/functions';

import { PopupNoti } from '@/components/screen/common/PopupNoti';
import SelectTheaterMobile from '@/components/selectTheaterMobile';
import useContentGlobal from '@/hooks/useContentGlobal';
import useSavaSessionKey from '@/hooks/useSavaSessionKey';
import useStatePopupNotify from '@/hooks/useStatePopupNotify';
import { sendLog } from '@/services/sendLog';
import router from 'next/router';

type TFormCareer = {
  careerID: string;
  careerTitle: string;
  onSuccess?: () => void;
  onFailure?: () => void;
  isPopup?: boolean
}

type TListField = [nameField: string, valueCheck: string | boolean][];

const initErrorMessage = {
  cv: "",
  name: "",
  email: "",
  career_id: "",
  career_title: "",
  phoneNumber: "",
  position: "",
  location: ""
}

const FormCareer: React.FC<TFormCareer> = ({ careerID, careerTitle, onSuccess, onFailure, isPopup }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorField, setErrorField] = useState(initErrorMessage);
  const [fileCV, setFileCV] = useState<HTMLInputElement | any>(null);
  const { popupNotify, handleOpenNotify, handleClosedNotify } = useStatePopupNotify();
  const [location, setLocation] = useState<TCinima | undefined>()
  const { session_key } = useSavaSessionKey();
  const { getValueByKey } = useContentGlobal();

  const formRef = useRef<HTMLFormElement | any>(null);

  const handleSetErrorMessage = (message: string) => (
    listError: any,
    field: string,
    value: string | boolean
  ) => {
    if (!value) return { ...listError, [field]: message };
    return { ...listError, [field]: "" }
  }

  const checkEmptyField = handleSetErrorMessage(getValueByKey(["Message", "Error", "Empty_Field"]));
  const checkErrorEmail = handleSetErrorMessage(getValueByKey(["Message", "Error", "Email"]));
  const checkPhoneVN = handleSetErrorMessage(getValueByKey(["Message", "Error", "Phone_Number"]));

  const handleGetValueForm = (indexValue: number[]): string[] => {
    return indexValue.map((currentValue: number) => {
      return formRef.current?.[currentValue]?.value ?? ""
    }, [])
  }

  const handleGetName = (cinema: any) => {
    const alias = toggleLanguage(cinema, "alias");
    if (alias) return alias;

    return toggleLanguage(cinema, "name");
  }

  const handleCheckField = (
    listError: any,
    listField: TListField,
    funcCheck: (
      listError: any,
      field: string,
      value: string | boolean
    ) => any
  ) => {
    let _errorField = listError;
    listField.map(item => {
      _errorField = funcCheck(_errorField, item[0], item[1])
    });
    return _errorField;
  }

  const handleCheckFile = (e: any) => {
    const file = e.target.files?.[0];
    if (file?.type !== "application/pdf") {
      setFileCV(null)
      return setErrorField({ ...errorField, cv: getValueByKey(["Message", "Error", "File_CV"]) });
    }

    setErrorField({ ...errorField, cv: "" });
    return setFileCV(file)
  }

  const handleCheckValueForm = () => {
    if (formRef.current === null) return;
    const [name = "", email = "", phoneNumber = "", position = ""] = handleGetValueForm([0, 1, 2, 3]);

    const _listField: TListField = [
      ["name", name],
      ["email", email],
      ["phoneNumber", phoneNumber],
      ["position", position],
      ["location", handleGetName(location)],
      ["cv", fileCV]
    ];

    let _errorField = handleCheckField(errorField, _listField, checkEmptyField);

    if (phoneNumber)
      _errorField = handleCheckField(_errorField, [["phoneNumber", isValidPhoneNumber(phoneNumber)]], checkPhoneVN);

    if (email && !isEmail(email)) {
      _errorField = handleCheckField(_errorField, [["email", isEmail(email)]], checkErrorEmail)
    }

    setErrorField(_errorField);
  }

  const cutUrl = (inputUrl: string): string => {
    if (inputUrl.includes("/graphql"))
      return inputUrl.replace("/graphql", "");
    return inputUrl;
  }

  const handleSubmitForm = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    const [name = "", email = "", phoneNumber = "", position = ""] = handleGetValueForm([0, 1, 2, 3]);

    if (!isEmail(email) || !isValidPhoneNumber(phoneNumber) || fileCV === null || !location) return setIsLoading(false);

    try {
      const formData = new FormData;

      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone_number", phoneNumber);
      formData.append("cv", fileCV);
      formData.append("career_id", careerID);
      formData.append("career_title", careerTitle);
      formData.append("position", position)
      formData.append("location", handleGetName(location))
      const res: any = await fetch(`${cutUrl(process.env.NEXT_PUBLIC_API_URL_MAGENTO as string)}/career/cv/apply`, {
        method: "POST",
        body: formData,
      });

      if (res?.status === 200) {
        onSuccess?.();
        handleOpenNotify(getValueByKey(["Message", "Success", "Apply_Job"]));
      }
      e?.target?.reset();
      setFileCV(null);
      setIsLoading(false);
      return;
    } catch (error) {
      onFailure?.();
      handleOpenNotify(getValueByKey(["Message", "Error", "Had_Error"]));
      setIsLoading(false);
      sendLog({
        action: "apply_career",
        page_name: "apply_career",
        page_url: router.asPath,
        session_key: session_key,
        status: "fail",
        error_message: JSON.stringify(error),
        information: "Apply career fail",
        request_json: JSON.stringify(e)
      })
      return;
    }
  }


  const handleChangeLocation = (location: TCinima) => {
    setLocation(location)
    setErrorField(prev => ({...prev, location: ""}))
  }

  return (
    <LoadingOverlay loading={isLoading}>
      <PopupNoti
        isOpen={popupNotify.isOpen}
        handleClose={handleClosedNotify}
        content={popupNotify.message} />
      <form className={`form ${isPopup && "form-career-popup"}`} ref={formRef} onSubmit={handleSubmitForm}>
        <div className="heading !mb-[0rem]">
          <p>{getValueByKey("Contact_Us")}</p>
        </div>
        <div className="txt !text-[gray]">
          <p>Email: hr@cinestar.com.vn</p>
        </div>
        <div className="form-list !gap-y-[2.5rem]">
          <div className="form-it">
            <label className="form-label !mb-[0.4rem]">{getValueByKey("Full_Name")} <span className='text-error'>*</span></label>
            <div>
              <Input
                className="form-control input"
                type="text"
                placeholder={getValueByKey("Full_Name")}
                id="contact-fName"
                required
                messageError={errorField.name} />
            </div>
          </div>
          <div className="form-it">
            <label className="form-label !mb-[0.4rem]">Email <span className='text-error'>*</span></label>
            <div>
              <Input
                className="form-control input"
                type="email"
                placeholder="Email"
                id="contact-email"
                required
                messageError={errorField.email} />
            </div>
          </div>
          <div className="form-it">
            <label className="form-label !mb-[0.4rem]">{getValueByKey("Phone_Number")} <span className='text-error'>*</span></label>
            <div>
              <Input
                className="form-control input"
                type="tel"
                placeholder={getValueByKey("Phone_Number")}
                id="contact-phone"
                required
                messageError={errorField.phoneNumber} />
            </div>
          </div>
          <div className="form-it">
            <label className="form-label !mb-[0.4rem]">{getValueByKey("job_position") || "Vị trí làm việc"} <span className='text-error'>*</span></label>
            <div>
              <Input
                className="form-control input"
                type="text"
                placeholder={getValueByKey("job_position") || "Vị trí làm việc"}
                id="contact-position"
                required
                messageError={errorField.name} />
            </div>
          </div>
          <div className="form-it relative">
            <label className="form-label !mb-[0.4rem]">{getValueByKey("positon") || "Nơi làm việc"} <span className='text-error'>*</span></label>
            <div className={`select-location-header filter-career-select filter-career-form ${errorField?.location?.length > 0 && "error-field"}`}>
              <SelectTheaterMobile customClassWrap="booking-cinestar-heading flex justify-end items-center" value={{
                value: location?.id,
                label: handleGetName(location)
              }} customOnChange={handleChangeLocation} placeholder={"Chọn rạp"} customPopup={{ popupclassname: "select-localtion-header__popup", poppopupmatchselectwidth: false }} isHideTitle />
            </div>
            {errorField?.location?.length > 0 && <p className="absolute text-error text-[12px]">{errorField.location}</p>}
          </div>
          <div className="form-it">
            <label className="form-label !mb-[0.4rem]">{getValueByKey("Up_CV")} (<span className='text-error'>*</span>PDF)</label>
            <div className='input relative' style={errorField.cv && !fileCV?.name ? {
              borderColor: "rgb(239, 68, 68)"
            } : {}}>
              <Input
                className="form-control"
                type="file"
                placeholder="Vị trí ứng tuyển"
                id="contact-cv"
                accept=".pdf"
                required
                messageError={errorField.cv}
                onChange={handleCheckFile} />
              <div className='absolute top-1/2 translate-y-[-50%] left-[10px] pointer-events-none'>
                {fileCV?.name ?? ""}
              </div>
            </div>
          </div>
          <div className="form-it">
            <button className="btn btn-submit btn--pri" type='submit' onClick={handleCheckValueForm}>{getValueByKey("Send")}</button>
          </div>
        </div>
      </form>
    </LoadingOverlay>
  )
}

export default FormCareer